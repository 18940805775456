@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

a {
  text-decoration: none !important;
}

body {
  scroll-behavior: smooth;
  // user-select: none;
  // -moz-user-select: none;
  -webkit-user-drag: none;
  overflow-x: hidden;
}

* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
  font-family: "Barlow", sans-serif !important;
}



.timer h1 {
  display: inline-block;
  padding-left: 15px;
  text-align: center;
  color: #BCA8FF !important;
}

#second {
  animation: ss 4s linear 2s infinite normal forwards;
}

@keyframes ss {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0.5;
  }
}

.timer {
  margin-bottom: 25px !important;
}

.timer small {
  font-weight: 300 !important;
  font-size: 11px !important;
}

.timer strong {
  font-size: 44px !important;
}

.scc {
  animation: sc 2s linear 0s infinite alternate-reverse forwards;
}

@keyframes sc {
  0% {
    transform: scale(1);
    opacity: 0.5;


  }

  100% {
    transform: scale(1.3);
    opacity: 1;

  }
}

@media (max-width: 780px) {}

::-webkit-scrollbar-track {
  background: #000 !important;
}

::-webkit-scrollbar-thumb {
  background: #ffffff !important;
  border-radius: 16px !important;
}

::-webkit-scrollbar {
  width: 4px !important;
}

.faq-row {
  margin: 20px auto !important;
  padding-left: 20px !important;
  // background: #09152a !important;
  background: #27282b;
  // box-shadow: 1px 1px 20px #083235 ,  -1px -1px 20px #083235;
  border-radius: 5px;
  border-bottom: none !important;
  width: 70% !important;
  box-sizing: content-box;
}

@media (prefers-reduced-motion: reduce) {

  .fa-spin {
    -webkit-animation-delay: -1ms;
    animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
    animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s;
  }
}

.fa-spin {
  -webkit-animation-name: fa-spin;
  animation-name: fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0);
  animation-delay: var(--fa-animation-delay, 0);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear);
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.pric {
  h1 {
    margin: 20px 0 !important;
    font-size: 36px !important;
    font-weight: bolder !important;
  }

  p {
    margin: 20px 0 !important;
    font-size: larger;



  }

  h2 {
    margin: 20px 0 !important;
    font-size: 26px !important;
    font-weight: bold !important;
  }
}